<template>
  <component :is="component" v-if="component" />
</template>

<script>
import Abdiibrahim from '@/views/booths/Abdiibrahim.vue';
import Deva from '@/views/booths/Deva.vue';
import Bilim from '@/views/booths/Bilim.vue';
import Sanofi from '@/views/booths/Sanofi.vue';

export default {
  data() {
    return {
      component: null,
    };
  },
  components: {
    Abdiibrahim,
    Deva,
    Bilim,
    Sanofi,
  },
  computed: {},
  mounted() {
    this.component = this.$options.components[this.$route.params.id];
  },
};
</script>

<style>
</style>
